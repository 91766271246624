import styled from 'styled-components';
import {
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';

export const LoginContentContainer = styled(DialogContent)`
  &.MuiDialogContent-dividers {
    padding: 10px 70px 40px;
  }

  .step-title {
    line-height: 150%;
    text-align: center;
    font-size: 30px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 900;
  }
  
  .step-description {
    margin-top: 10px;
    text-align: center;
  }

  .step-result {
    font-size: 30px;
    text-align: center;
  }

  .slick-list {
    max-width: 500px;
    margin: auto;
  }

  @media (max-width: 768px) {
    &.MuiDialogContent-dividers {
      padding: 10px 30px 40px;
    }
  }

  @media (max-width: 576px) {
    &.MuiDialogContent-dividers {
      padding: 10px 20px 30px;
    }
  }
`;

export const MyAutocompleteContainer = styled.div`
  .css-2b097c-container {
    z-index: 10;
  }

  .css-yk16xz-control,
  .css-1pahdxg-control {
    border-radius: 0;
    height: 54px;
    font-weight: 600;
    font-size: 15px;
    padding-left: 3px;
  }

  .css-1pahdxg-control,
  .css-1pahdxg-control:hover {
    border-color: #3f51b5;
  }
`;