import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { appTheme } from 'common/theme/app';
import { GlobalStyle } from 'containers/App/app.style';
import { ResetCSS } from 'common/assets/css/style';
import CircularProgressOverlay from 'common/mycomponents/CircularProgressOverlay';
import LoginSection from '../containers/App/LoginSection';

const LoginPage = () => {
  return (
    <ThemeProvider theme={appTheme}>
      <Fragment>
        <ResetCSS />
        <GlobalStyle />
        <CircularProgressOverlay>
          <LoginSection />
        </CircularProgressOverlay>
      </Fragment>
    </ThemeProvider>
  );
};

export default LoginPage;