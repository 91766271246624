import React, { useState, useEffect, useContext } from 'react';
import { navigate } from 'gatsby';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import { CircularProgressContext } from 'common/mycomponents/CircularProgressOverlay';
import Slider from 'react-slick';
import ReplayTwoToneIcon from '@material-ui/icons/ReplayTwoTone';
import GooglePlacesAutocomplete, { geocodeByAddress } from 'react-google-places-autocomplete';
import MyInput from 'common/mycomponents/MyInput';
import MyDescription from 'common/mycomponents/MyDescription';
import MyButton from 'common/mycomponents/MyButton';
import { notNull } from 'utils/text';
import { MyCardRow } from 'common/mycomponents/MyCard';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LoginContentContainer, MyAutocompleteContainer } from './style';
import { portalService } from 'services/portalService';
const awsConfig = {
  Auth: {
    region: process.env.AWS_CONFIG_REGION,
    userPoolId: process.env.AWS_CONFIG_USER_POOL_ID,
    userPoolWebClientId: process.env.AWS_CONFIG_USER_POOL_WEBCLIENT_ID,
    identityPoolId: process.env.AWS_CONFIG_IDENTITY_POOL_ID,
  },
};

Amplify.configure(awsConfig);

const LoginSection = () => {
  const [signedUser, setSignedUser] = useState(null);
  const [step, setStep] = useState(0);
  const [formValues, setFormValues] = useState(null);
  const [formErrors, setFormErrors] = useState(null);
  const [userNotConfirmed, setUserNotConfirmed] = useState(false);
  const { showCircularProgress, hideCircularProgress } = useContext(CircularProgressContext);

  let slider;
  const sliderSettings = {
    adaptiveHeight: true,
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
  };

  useEffect(() => {
    if (slider) {
      slider.slickGoTo(step);
    }
  }, [step]);

  const handleValueChange = (id, value) => {
    setFormValues({
      ...formValues,
      [id]: value,
    });
  };

  const checkValueValidate = (id, value) => {
    let valid = notNull(value) && value.length > 0;
    let error = null;

    if (!valid) {
      error = 'Please input value';
    } else {
      if (id === 'username') {
        valid = value.length > 5;
        if (!valid) {
          error = valid ? null : 'Please input 5 characters at least';
        } else {
          valid = value.match(/^([a-zA-Z0-9._]+)$/);
          error = valid ? null : 'Please input valid user name without space or special characters';
        }
      } else if (id === 'email') {
        valid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        error = valid ? null : 'Please input valid Email';
      } else if (id === 'cemail') {
        valid = formValues.email === formValues.cemail;
        error = valid ? null : 'Please input correctly';
      }
    }

    return error;
  };

  const checkStepValidate = (keys) => {
    let errors = {};
    keys.forEach(id => {
      const error = checkValueValidate(id, notNull(formValues) ? formValues[id] : null);
      errors[id] = error;
    })
    setFormErrors(errors);

    const stepValid = Object.keys(errors).filter(item => errors[item] !== null).length;
    return stepValid === 0;
  };

  const handleStep1Next = async () => {
    const keys = ['email', 'cemail'];
    if (!checkStepValidate(keys)) {
      return;
    }

    showCircularProgress('Please wait...');
    try {
      const user = await Auth.signUp({
        username: formValues.email,
        password: process.env.AWS_CONFIG_USER_NORMAL_PASSWORD,
        attributes: {
          email: formValues.email,
        },
      });
      console.log('AWS SIGNUP SUCCESS : ', user);
      setSignedUser(user);
      hideCircularProgress();
      setStep(1);
    } catch (err) {
      console.log('AWS SIGNUP ERROR : ', err);
      if (err.code === 'UsernameExistsException') {
        try {
          const signedUser = await Auth.signIn(formValues.email, process.env.AWS_CONFIG_USER_NORMAL_PASSWORD);
          hideCircularProgress();
          setFormErrors({
            email: err.message
          });
        } catch (error) {
          console.log(error);
          hideCircularProgress();
          if (error.code === 'UserNotConfirmedException') {
            setUserNotConfirmed(true);
            await handleResendCode();
            setStep(1);
          }
        }
      } else {
        hideCircularProgress();
      }
    }
  }

  const handleStep2Next = async () => {
    const keys = ['verification_code'];
    if (!checkStepValidate(keys)) {
      return;
    }

    showCircularProgress('Verifying...');
    try {
      await Auth.confirmSignUp(formValues.email, formValues.verification_code);
      console.log('AWS CODE VERIFY SUCCESS');
      hideCircularProgress();
      setStep(2);
    } catch (err) {
      hideCircularProgress();
      console.log('error confirming sign up', err);
      setFormErrors({
        verification_code: err.message
      });
    }
  }

  const handleResendCode = async () => {
    showCircularProgress('Please wait...');
    try {
      await Auth.resendSignUp(formValues.email);
      console.log('AWS CODE RESEND SUCCESS');
      hideCircularProgress();
    } catch (err) {
      hideCircularProgress();
      console.log('error resending code', err);
    }
  }

  const onGoogleMapSelected = async ({label, value}) => {
    geocodeByAddress(value.description).then(results => {
      console.log(results);
      const tmpValues = {
        city: '',
        state: '',
        address: '',
        zip: '',
      };

      for (var i = 0; i < results[0].address_components.length; i++) {
        if (results[0].address_components[i].types[0]) {
          if (
            results[0].address_components[i].types.includes('locality') ||
            results[0].address_components[i].types.includes('sublocality')
          )
            tmpValues.city = results[0].address_components[i].long_name;
          else if (results[0].address_components[i].types.includes('street_number'))
            tmpValues.address = results[0].address_components[i].long_name + ' ';
          else if (results[0].address_components[i].types.includes('route'))
            tmpValues.address += results[0].address_components[i].long_name;
          else if ('administrative_area_level_1' === results[0].address_components[i].types[0])
            tmpValues.state = results[0].address_components[i].short_name;
          else if ('postal_code' === results[0].address_components[i].types[0])
            tmpValues.zip = results[0].address_components[i].long_name;
        }
      }

      setFormValues({
        ...formValues,
        ...tmpValues
      });
    });
  };

  const handleStep3Next = async () => {
    const keys = ['business_name', 'address', 'city', 'state', 'zip'];
    if (!checkStepValidate(keys)) {
      return;
    }

    showCircularProgress('Creating Portal Account...');
    portalService.create_portal_instance(formValues).then(({ data }) => {
      hideCircularProgress();
      if (data.status === 'SUCCESS') {
        navigate('/loginResult?type=success');
      } else {
        navigate('/loginResult?type=fail');
      }
    });
  }

  return (
    <LoginContentContainer className="p-0">
      <Slider ref={c => slider = c} {...sliderSettings}>
        <div>
          <div style={{ padding: '0 5px' }}>
            <MyCardRow direction="column">
              <MyDescription>
                <p className="step-title">Step 1</p>
                <p className="step-description"><span style={{ fontSize: 20 }}>Email Verification.</span><br/>Please enter your email address below.</p>
              </MyDescription>
              <MyInput
                label="Email"
                id="email"
                values={formValues}
                onChange={handleValueChange}
                error={notNull(formErrors?.email)}
                helperText={formErrors?.email}
              />
              <MyInput
                label="Confirm Email"
                id="cemail"
                values={formValues}
                onChange={handleValueChange}
                error={notNull(formErrors?.cemail)}
                helperText={formErrors?.cemail}
              />
              <MyButton
                id="signup_email_confirm"
                size="small"
                color="primary"
                onClick={handleStep1Next}
              >
                <span className="btn-wrapper--label">NEXT</span>
              </MyButton>
            </MyCardRow>
          </div>
        </div>

        <div>
          <div style={{ padding: '0 5px' }}>
            <MyCardRow direction="column">
              <MyDescription>
                <p className="step-title">Step 2</p>
                {
                  userNotConfirmed ? 
                  <p className="step-description error-color">You already tried to login before but not confirmed yet. Please verify again.</p> :
                  <p className="step-description">We sent a verification code to your email.<br/>Please check your email and enter the code we sent you below.</p>
                }
              </MyDescription>
              <MyCardRow>
                <MyInput
                  label="Verification Code"
                  id="verification_code"
                  values={formValues}
                  onChange={handleValueChange}
                  error={notNull(formErrors?.verification_code)}
                  helperText={formErrors?.verification_code}
                />
                <MyButton
                  id="signup_verification_resend"
                  size="small"
                  color="primary"
                  maxWidth="150px"
                  onClick={handleResendCode}
                >
                  <span className="btn-wrapper--icon">
                    <ReplayTwoToneIcon fontSize="small" />
                  </span>
                  <span className="btn-wrapper--label">Resend</span>
                </MyButton>
              </MyCardRow>
              
              <MyCardRow className="mt-2">
                <MyButton
                  id="signup_verification_confirm"
                  size="small"
                  color="primary"
                  onClick={handleStep2Next}
                >
                  <span className="btn-wrapper--label">NEXT</span>
                </MyButton>
              </MyCardRow>
            </MyCardRow>
          </div>
        </div>

        <div>
          <div style={{ padding: '0 5px' }}>
            <MyCardRow direction="column">
              <MyDescription>
                <p className="step-title">Step 3</p>
                <p className="step-description">Almost Done. Please fill in your address.</p>
              </MyDescription>
              <MyInput
                label="Business Name"
                id="business_name"
                values={formValues}
                onChange={handleValueChange}
                error={notNull(formErrors?.business_name)}
                helperText={formErrors?.business_name}
              />
              <MyDescription>
                <MyAutocompleteContainer>
                  <GooglePlacesAutocomplete
                    apiKey={process.env.GOOGLE_MAP_API_KEY}
                    selectProps={{
                      value: null,
                      onChange: onGoogleMapSelected,
                      placeholder: 'Enter your address here:'
                    }}
                  />
                </MyAutocompleteContainer>
                
              </MyDescription>
              <MyCardRow>
                <MyInput
                  label="Street Address"
                  id="address"
                  values={formValues}
                  onChange={handleValueChange}
                  error={notNull(formErrors?.address)}
                  helperText={formErrors?.address}
                  disabled
                />
              </MyCardRow>
              <MyCardRow>
                <MyInput
                  label="City"
                  id="city"
                  values={formValues}
                  onChange={handleValueChange}
                  error={notNull(formErrors?.city)}
                  helperText={formErrors?.city}
                  disabled
                />
                <MyInput
                  label="State"
                  id="state"
                  values={formValues}
                  onChange={handleValueChange}
                  error={notNull(formErrors?.state)}
                  helperText={formErrors?.state}
                  disabled
                />
                <MyInput
                  label="Zip / Postal"
                  id="zip"
                  values={formValues}
                  onChange={handleValueChange}
                  error={notNull(formErrors?.zip)}
                  helperText={formErrors?.zip}
                  disabled
                />
              </MyCardRow>
              <MyCardRow className="mt-2">
                <MyButton
                  id="signup_done"
                  size="small"
                  color="primary"
                  onClick={handleStep3Next}
                >
                  <span className="btn-wrapper--label">DONE</span>
                </MyButton>
              </MyCardRow>
            </MyCardRow>
          </div>
        </div>
      </Slider>
    </LoginContentContainer>
  );
};

export default LoginSection;
